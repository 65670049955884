import { useManageUserPageSettingsMutation } from "../api-integration/secure/secure";

const useManageUserPageSettings = () => {
    const [
        manageUserPageSettingsAPI,
        {
            data: manageUserPageSettingsData,
            isLoading: isManageUserPageSettingsLoading,
            isSuccess: isManageUserPageSettingsSuccess,
            isError: isManageUserPageSettingsError,
            error: manageUserPageSettingsError,
        },
    ] = useManageUserPageSettingsMutation();

    return {
        manageUserPageSettingsAPI,
        manageUserPageSettingsData,
        isManageUserPageSettingsLoading,
        isManageUserPageSettingsSuccess,
        isManageUserPageSettingsError,
        manageUserPageSettingsError,
    };
};

export default useManageUserPageSettings;