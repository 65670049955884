import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Edit from '../../../assets/icons/pen.svg';
import TooltipComponent from '../bootstrap-component/tooltip-component';
import { Modal } from 'bootstrap';
import EditTranslationKeyModal from '../modal/edit-translation-key-modal';
import { getTranslatedText } from '../../../util/util';
import usePageTranslation from '../../../hooks/usePageTranslation';

interface EditTranslationIconProps {
  translationKey: string;
  translationType?: string;
}

const EditTranslationIcon: React.FC<EditTranslationIconProps> = ({ translationKey, translationType }) => {
  const navigate = useNavigate();
  const PageTranslation = usePageTranslation();
  let translationTooltip: string;
  const [localTranslationKey, setLocalTranslationKey] = useState("");

  switch (translationType) {
    case 'text':
      translationTooltip = 'Edit text';
      break;
    case 'tooltip':
      translationTooltip = 'Edit tooltip';
      break;
    case 'placeholder':
      translationTooltip = 'Edit placeholder';
      break;
    case 'news':
      translationTooltip = 'Edit News';
      break;
    default:
      translationTooltip = 'Edit text'; 
  }

  // useEffect(() => {
  //   if(localTranslationKey) {
  //     const myModalEl = document.getElementById('editTranslationKeyModalId'); 
  //       if (myModalEl) {
  //           const modal = Modal.getOrCreateInstance(myModalEl);
  //           if (modal) {
  //               modal?.show(myModalEl);
  //           }
  //       }
  //   }
  // },[localTranslationKey]);

  return (
    <>
    <TooltipComponent title={translationTooltip}>
      <img
        src={Edit}
        alt= {getTranslatedText('imageAlt.text', PageTranslation)}
        className="editIconSize cursor-pointer"
        onClick={() => {
          navigate('/app/manageTranslation', { state: { passedTranslationKey: translationKey } });
          // setLocalTranslationKey(translationKey);
        }}
      />
    </TooltipComponent>
    {/* <EditTranslationKeyModal 
      id='editTranslationKeyModalId'
      translationKey={localTranslationKey} 
    /> */}
    </>
  );
};

export default EditTranslationIcon;