// useInitializePopovers.ts
import { useEffect, useState } from 'react';
import { Popover } from 'bootstrap';
import { generatePopoverContent } from '../../../util/util';
import { useSelector } from 'react-redux';


const useInitializePopovers = () => {
    const { user } = useSelector((state: any) => state.commonSlice);
    const [notifications, setNotifications] = useState<{ NOTIFICATION: string, REDIRECTURL: string, OBJTYPE: string, NOTIFICATIONAGE: string, OBJID: number }[]>([]);

    useEffect(() => {
        setNotifications(user?.userNotifications);
    }, [user?.userNotifications]);

    useEffect(() => {
        const popoverTriggerList: NodeListOf<Element> = document.querySelectorAll('[data-bs-toggle="popover"]');
        const popoverList: Popover[] = Array.from(popoverTriggerList).map(popoverTriggerEl => {
            return new Popover(popoverTriggerEl, {
                html: true,
                //trigger: 'focus',
                content: generatePopoverContent(notifications)
            });
        });

        // Cleanup function to destroy popovers when component unmounts or notifications change
        return () => {
            popoverList.forEach(popover => popover.dispose());
        };
    }, [notifications]);
};

export default useInitializePopovers;