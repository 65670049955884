import { useEffect, useState } from "react";
import TooltipComponent from "../../common/bootstrap-component/tooltip-component";
import FilledStarSvg from "../../common/icons/filled-star-svg";
import HollowStarSvg from "../../common/icons/hollow-star-svg";


interface StarRatingProps {
  maxRating: number;
  defaultRating: number;
  messages?: any;
  className?: string;
  onSetRating?: (rating: number) => void;   // Can be used if required in the future...
  onTempRating: (rating: number) => void;
}

const StarRating: React.FC<StarRatingProps> = (props) => {
  const [rating, setRating] = useState(props.defaultRating);  // This state is used to set the stars.
  const [tempRating, setTempRating] = useState(0);  // This state is used to change the stars while hovering over them.

  // This useEffect will be responsible to change the value of rating state when the props.defaultRating changes.
  useEffect(() => {
    setRating(props.defaultRating);
  },[props.defaultRating])

  // Handles the temporary rating which will be used in the star-comment-modal as a prop.
  const handleTempRating = (rating: number) => {
    props.onTempRating(rating);
  }

  return (
    <div>
      {Array.from({ length: props.maxRating }, (_, i) => {
         const isHovered = tempRating >= i + 1;
         const isFilled = rating >= i + 1 && tempRating === 0;
         const isFirstStar = i === 0;
 
         // Determine the color for the star
         let color = isFilled ? props.messages[i]?.color : '#007dbb';
         if (isFirstStar) {
           if (tempRating === 1) {
             color = 'var(--bs-gray)';
           } else if (rating > 1 || tempRating > 1) {
             color = 'var(--bs-primary)';
           }
         } else if (isHovered) {
           color = props.messages[i]?.color;
         }
        return (
          <TooltipComponent key={i} title={props.messages[i]?.tooltip || ''}>
            <span
              role="button"
              onClick={() => handleTempRating(i + 1)}
              onMouseEnter={() => setTempRating(i + 1)}
              onMouseLeave={() => setTempRating(0)}
            >
              {isFilled || isHovered ? (
                <span className="me-2">
                  <FilledStarSvg color={color} isModalOpen={true} size={20} />
                </span>
              ) : (
                <span className="me-2">
                  <HollowStarSvg color={color} isModalOpen={true} size={20} />
                </span>
              )}
            </span>
          </TooltipComponent>
        );
      })}
    </div>
  )
}

export default StarRating;
