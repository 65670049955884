import { useEffect, useState } from "react";
import TooltipComponent from "../../common/bootstrap-component/tooltip-component";
import FilledStarSvg from "../../common/icons/filled-star-svg";
import HollowStarSvg from "../../common/icons/hollow-star-svg";
import { initializeModal, hideModal, getTranslatedText } from "../../../util/util";
import usePageTranslation from "../../../hooks/usePageTranslation";
import { useSelector } from "react-redux";
import EditTranslationIcon from "../../common/edit-translation-icon/EditTranslationIcon";

interface StarCommentModalProps {
    id: string;
    maxRating: number;
    rating: number;
    handleRate: (rating: number, comment: string) => void;
    messages: any;
}

const StarCommentModal: React.FC<StarCommentModalProps> = ({id, maxRating, rating, handleRate, messages}) => {
    const PageTranslation = usePageTranslation();
    const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
    const [comment, setComment] = useState<string>("");
    const [isCommentError, setIsCommentError] = useState<boolean>(false);

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id, resetModalValues);
    
        return () => {
          // Call the initializeModal function on unmount/cleanup
          initializeModal(id, resetModalValues);
        };
    }, [id]);

    const resetModalValues = () => {
        setIsCommentError(false);
        setComment("");
    }

    
    // This function handles the validation of comment and calling of API.
    const handleRateBtn = () => {
        if(rating === 1 && comment.trim().length === 0)
        {
            setIsCommentError(true);
            return;
        }
        // This function is used to hide the modal.
        hideModal(id);
        handleRate(rating,comment);
    }

    // Handles onChange event of comment input field.
    const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setComment(value);
        setIsCommentError(value.trim().length === 0);
    }    

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${id}Label`}>
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('commentRatings.text',PageTranslation)}}/>
                            {TranslationEditFlag && <EditTranslationIcon translationKey='commentRatings.text' translationType='text'/>}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-3 mb-4">
                                <span dangerouslySetInnerHTML={{__html: getTranslatedText('ratedStars.text',PageTranslation)}}/>
                                {TranslationEditFlag && <EditTranslationIcon translationKey='ratedStars.text' translationType='text'/>}
                            </div>
                            <div className="col-md-9 mb-4 disabled" style={{ cursor: 'not-allowed' }}>
                            {Array.from({ length: maxRating }, (_, i) => {
                                const message = messages && messages[i] ? messages[i] : { color: '', tooltip: '' };
                                // Determine the color for the star
                                const color = (i === 0 && rating > 1) ? 'var(--bs-primary)' : (i < rating ? message.color : '#007dbb');
                                return (
                                    i < rating ? (
                                        <FilledStarSvg key={i} color={color} isModalOpen={false} size={20} />
                                    ) : (
                                        <HollowStarSvg key={i} color={color} isModalOpen={false} size={20}/>
                                    )
                                );
                            })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {TranslationEditFlag && <EditTranslationIcon translationKey='comment.placeholder' translationType='placeholder'/>}
                                <input 
                                type="text" 
                                className = {`form-control ${isCommentError ? 'is-invalid' : ''}`} 
                                placeholder={getTranslatedText('comment.placeholder',PageTranslation)} 
                                value={comment}
                                onChange={handleComment}
                                />
                                <div className="invalid-feedback">
                                    {isCommentError && (
                                        <>
                                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('comment.required.message',PageTranslation)}}/>
                                            {TranslationEditFlag && <EditTranslationIcon translationKey='comment.required.message' translationType='text'/>}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <TooltipComponent title={getTranslatedText('close.tooltip',PageTranslation)}>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            >
                                <span dangerouslySetInnerHTML={{__html: getTranslatedText('close.text',PageTranslation)}}/>
                            </button>
                        </TooltipComponent>
                        {TranslationEditFlag && (
                            <>
                                <EditTranslationIcon translationKey='close.text' translationType='text'/>
                                <EditTranslationIcon translationKey='close.tooltip' translationType='tooltip'/>
                            </>
                        )}
                        <TooltipComponent title= {getTranslatedText('rate.tooltip',PageTranslation)}>
                    <button type="button" className="btn btn-primary" onClick={handleRateBtn}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('rate.text',PageTranslation)}}/>
                    </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey='rate.text' translationType='text'/>
                            <EditTranslationIcon translationKey='rate.tooltip' translationType='tooltip'/>
                        </>
                    )}
                </div>
                </div>
            </div>
        </div>
    )
}

export default StarCommentModal;