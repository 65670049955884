import usePageTranslation from "../../../hooks/usePageTranslation";
import { useSelector } from "react-redux";
import { getPageByURL, getTranslatedText } from "../../../util/util";
import EditTranslationIcon from "../edit-translation-icon/EditTranslationIcon";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const PageTranslation = usePageTranslation();
  const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
  const location = useLocation();
  const footerPageName = getPageByURL(location.pathname);
  
  return (
    <>
    {footerPageName === 'login' ? null : (
      <footer className="">
        <div className="text-end p-5 me-5">
          <a className="h6 text-decoration-none" target="_blank" href="https://bluecallom.com/about">
            <span dangerouslySetInnerHTML={{ __html: getTranslatedText('about.text',PageTranslation) }} />
          </a>
          {TranslationEditFlag && <EditTranslationIcon translationKey='about.text' translationType='text'/>}
          | <a className="h6 text-decoration-none" target="_blank" href="https://bluecallom.com/contact">
            <span dangerouslySetInnerHTML={{ __html: getTranslatedText('contact.text',PageTranslation)   }} />
          </a> 
          {TranslationEditFlag && <EditTranslationIcon translationKey='contact.text' translationType='text'/>}
          | <a className="h6 text-decoration-none" target="_blank" href="https://bluecallom.com/terms/">
            <span dangerouslySetInnerHTML={{ __html: getTranslatedText('terms.text',PageTranslation)   }} />
          </a>
          {TranslationEditFlag && <EditTranslationIcon translationKey='terms.text' translationType='text'/>}
        </div>
      </footer>
    )}
    </>
  )
}

export default Footer;