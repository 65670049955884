import Card from "../../../common/card/card";
import { set, useForm } from "react-hook-form";
import { useCopyPromptsMutation, usePromptStatisticsMutation } from "../../../../api-integration/secure/prompts";
import { useEffect, useState } from "react";
import History from '../../../../assets/icons/history.svg';
import { fullPageLoader, updateAlertMessage } from "../../../../api-integration/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Flag from '../../../../assets/icons/bluePrompt.svg';
import Delete from '../../../../assets/icons/delete.svg';
import Embed from '../../../../assets/icons/code-outline.svg';
import TooltipComponent from "../../../common/bootstrap-component/tooltip-component";
import WelcomeNonLoginCard from "../../../common/modal/welcome-non-login-user";
import ConfirmationPopup from "../../../common/modal/confirmation-popup";
import { useGetUserProfileMutation, useRemovePromptFromLibMutation } from "../../../../api-integration/secure/secure";
import { getTranslatedText } from "../../../../util/util";
import usePageTranslation from "../../../../hooks/usePageTranslation";
import EditTranslationIcon from "../../../common/edit-translation-icon/EditTranslationIcon";
import PromptAdditionalInfoModal from "../../../common/modal/prompt-additional-info-modal";



interface askGptFormInterface {
  userPrompt: string,
  DUALPROMPTREFERENCEDATA: string,
  authorView: boolean,
  autoSave: boolean,
  ManualEffort: number,
  dontShowExeAlert: boolean,
}

interface Props {
  cardId:string;
  cardTitle: string;
  cardHelpTitle: string;
  cardHelpContent: string;
  prompt: any,
  TriggerAskGpt: (data: any) => void,
  askGptResponse: any,
  loading: boolean,
  isLibraryTypeChanged: boolean,
  setReloadPromptList: (reloadPromptList: boolean) => void;
  setWiderLayout: (widerLayout: boolean) => void;
  pageType?: string;
}

const PromptExecution = (props: Props) => {
  const promptId = props?.prompt?.GPTBLUEPROMPTID;
  const PageTranslation = usePageTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [userEmail, setUserEmail] = useState('');
  const [userPromptCCount, setUserPromptCCount] = useState(props?.prompt?.DEFAULTACTION ? 2500-props?.prompt?.DEFAULTACTION.length : 2500);
  const [referenceDataCCount, setReferenceDataCCount] = useState(props?.prompt?.DUALPROMPTREFERENCEDATA ? 2500-props?.prompt?.DUALPROMPTREFERENCEDATA.length : 2500);
  const [gain, setGain] = useState(2);
  const [showBluePrompt, setShowBluePrompt] = useState(false);
  const [localAuthorView, setLocalAuthorView] = useState(false);
  const [averageCCUsed, setAverageCCUsed] = useState(props?.prompt?.averageCC || 0);  
  const [editAccess, setEditAcccess] = useState(false);
  const [formData, setFormData] = useState<askGptFormInterface>();
  const { user, TranslationEditFlag } = useSelector((state: any) => state.commonSlice);
  const [dontShowExeAlert, setDontShowExeAlert] = useState<boolean>(true);
  const [isHidden, setIsHidden] = useState(props?.isLibraryTypeChanged);
  const [isAllowEmbedScript, setIsAllowEmbedScript] = useState(props?.prompt?.allowEmbedScript == 1 ? true : false);
  const { register, handleSubmit, trigger, formState: { errors }, setValue, getValues, watch, reset } = useForm<askGptFormInterface>();
  const userPromptHasValue = watch('userPrompt');
  const referenceDataHasValue = watch('DUALPROMPTREFERENCEDATA');
  const [layoutMode, setLayoutMode] = useState("0");
  const [bottomAppenderText, setBottomAppenderText] = useState('');
  
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const [getPromptsStatisticsAPI, { data: promptStats, isLoading, isSuccess: promptStatsIsSuccess, isError: promptStatsIsError, error }] =
    usePromptStatisticsMutation(); 
  
  const [copyPromptsAPI, { data: copyPromptsData, isLoading: isCopyPromptLoading, isSuccess: isCopyPromptSuccess, isError: isCopyPromptError, error: copyPromptError }] =
    useCopyPromptsMutation();
  
  const [getUserDetailsAPI, { data: userDetailsData, isLoading: isUserDetailsLoading , isSuccess: isUserDetailsSuccess, isError: isUserDetailsError, error: userDetailsError}] =
    useGetUserProfileMutation();
  const [removePromptFromLibAPI, { data: removePromptFromLibData, isLoading: isRemovePromptFromLibLoading, isSuccess: isRemovePromptFromLibSuccess, isError: isRemovePromptFromLibError, error: removePromptFromLibError }] =
    useRemovePromptFromLibMutation();

  const additionalFooterIcons = [
    {
      visibility: params.id && props?.prompt ? true : false,
      src: History,
      tooltip: "promptExecutionHistory.card.tooltip",
      onClick: () => {
        navigate(`/app/prompts/result/${props?.prompt?.GPTBLUEPROMPTID}`);
      },
    }
    //,
    // {
    //   src: History,
    //   tooltip: 'Tooltip for icon 2',
    //   onClick: () => {
    //     // Handle click event for icon 2
    //   },
    // },
    // Add more icons as needed
  ];

  //const showWelcomeCard = !(isLoggedIn || params.id == '' || !params.id || params.id == undefined || params.id == null); 
    
  const showWelcomeCard = !isLoggedIn && props.pageType != 'embed';

  const onSubmit = (data: askGptFormInterface) => {
    if (dontShowExeAlert) {
        props.TriggerAskGpt({
        ...data,
        authorView: data?.authorView ? 1 : 0,
        autoSave: data?.autoSave ? 1 : 0,
        promptExeAlert: 0  //value of dontShowExeAlert checkbox in modal is checked OR value from DB is set as True then pass 0 to prompt i.e. dont show execution alert in future
      })
    }
    else{
      setFormData(data);
    }
  }
  const handleConfirmPromptExecution = () => {
    if (formData) {
      props.TriggerAskGpt({
        ...formData,
        authorView: formData?.authorView ? 1 : 0,
        autoSave: formData?.autoSave ? 1 : 0,
        promptExeAlert: dontShowExeAlert ? 0 : 1 //if checkbox is checked i.e. dont show execution alert in future so pass 0 else pass 1
      })
      //setAverageCCUsed(props.averageCCNumber);
    }
  };
  
  const handleConfirmDeleteDownloadedPrompt = () => {
    dispatch(fullPageLoader(true));
    removePromptFromLibAPI({ "promptId": props?.prompt?.GPTBLUEPROMPTID });
  };

  const handleChange = (event: any) => {
    setUserEmail(event.target.value);
  };
  useEffect(() => {
    if (isCopyPromptSuccess) {
        dispatch(fullPageLoader(false));
        dispatch(updateAlertMessage({ status: 'success', message: copyPromptsData?.message }));
        navigate(`/app/prompts/edit/${copyPromptsData?.promptId}`)
    }
    if (isCopyPromptError) {
        dispatch(fullPageLoader(false));
        dispatch(updateAlertMessage({ status: 'error', message: copyPromptsData?.message }));
    }
  }, [isCopyPromptSuccess, isCopyPromptError]);

  useEffect(() => {
    if (isRemovePromptFromLibSuccess) {
      dispatch(fullPageLoader(false));
      if(removePromptFromLibData.success){
        dispatch(updateAlertMessage({ status: 'success', message: removePromptFromLibData?.message }));
        setIsHidden(true);
        navigate('/app/io');
        props?.setReloadPromptList(true);
      }else{
        dispatch(updateAlertMessage({ status: 'error', message: removePromptFromLibData?.message }));
      }
    }
    else if (isRemovePromptFromLibError || removePromptFromLibError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: removePromptFromLibData?.message }));
    }
  }, [isRemovePromptFromLibSuccess, isRemovePromptFromLibError, removePromptFromLibError, removePromptFromLibData]);

  useEffect(() => {
    setIsHidden(props?.isLibraryTypeChanged);
  }, [props?.isLibraryTypeChanged]);

  useEffect(() => {
    setIsAllowEmbedScript(props?.prompt?.allowEmbedScript == 1 ? true : false);
  }, [props?.prompt?.allowEmbedScript]);

  useEffect(() => {
    dispatch(fullPageLoader(true));
    getUserDetailsAPI({});
  }, []);
  useEffect(() => {
    if(isUserDetailsSuccess || isUserDetailsError || userDetailsError){
      dispatch(fullPageLoader(false));
    }
  }, [isUserDetailsSuccess, isUserDetailsError, userDetailsError]);

  useEffect(() => {
    if (promptId !== undefined) {
      reset();
      dispatch(fullPageLoader(true));
      getPromptsStatisticsAPI({ 
        objId: promptId, 
        statsType: "prompt"
      });
      setValue('userPrompt', props?.prompt?.DEFAULTACTION);
      setUserPromptCCount(2500 - props?.prompt?.DEFAULTACTION.length);
      setValue('DUALPROMPTREFERENCEDATA', props?.prompt?.DUALPROMPTREFERENCEDATA);
      setReferenceDataCCount(2500 - props?.prompt?.DUALPROMPTREFERENCEDATA.length);
      setLocalAuthorView(false);
      //setValue('authorView', props?.prompt?.AUTHORVIEW == 1 ? true : false);
      if(user?.promptExeAlert == 1){
        setDontShowExeAlert(false);
      }else{
        setDontShowExeAlert(true);
      }
      let userHasAccess = false;
      props?.prompt?.promptTeam?.map((promptUser: any) => {
        if (user?.userId == promptUser?.USERID) {
          userHasAccess = true
        }
      })
      if (user?.id == props?.prompt?.OWNERUSERID || userHasAccess) {
        setEditAcccess(true);
      } else {
        setEditAcccess(false);
      }
    }
  }, [promptId]);


  useEffect(() => {
    if (promptStatsIsSuccess) {
      if (promptStats?.success == false) {
        dispatch(updateAlertMessage({ status: 'error', message: promptStats?.message }));
      }
    }
    else if (promptStatsIsSuccess || promptStatsIsError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: promptStats?.message }));
    }
  }, [promptStatsIsSuccess, promptStatsIsError]);

  const selectLayoutMode = (e: any) => {
    setLayoutMode(e.target.value);
    if (e.target.value == 0) {
      props.setWiderLayout(true);
    } else {
      props.setWiderLayout(false);
    }
  }
  
  const copyPrompt = () => {
    dispatch(fullPageLoader(true));
    copyPromptsAPI({ "GPTBluePromptId": props?.prompt?.GPTBLUEPROMPTID });
  }

  useEffect(() => {
    if(props?.prompt?.averageCC) {
      setAverageCCUsed(props?.prompt?.averageCC);
    }
    else {
      setAverageCCUsed(0);
    }
  }, [props?.prompt]);

  useEffect(() => {
    if(props?.askGptResponse?.averageCC) {
      setAverageCCUsed(props?.askGptResponse?.averageCC);
    }
  }, [props?.askGptResponse]);

  useEffect(() => {
    if(props?.prompt?.BLUEPROMPT == 1) {
      setShowBluePrompt(true);
    }else {
      setShowBluePrompt(false);
    }
  }, [props?.prompt?.BLUEPROMPT]);

  useEffect(() => {
    if(props?.prompt?.OWNERFIRSTNAME && props?.prompt?.OWNERLASTNAME && props?.prompt?.OWNERCOMPANY){
      setBottomAppenderText(`${props?.prompt?.OWNERFIRSTNAME} ${props?.prompt?.OWNERLASTNAME}, ${props?.prompt?.OWNERCOMPANY}`);
    }
  }, [props?.prompt?.OWNERFIRSTNAME, props?.prompt?.OWNERLASTNAME, props?.prompt?.OWNERCOMPANY]);
  
  const handleChangeAuthorView = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalAuthorView(event.target.checked);
    //setValue('authorView', event.target.checked);
  };

  let generateResponseBtntooltip: string;

  if(props?.prompt?.DUALPROMPT){
    //dual prompt
    if (userPromptHasValue && referenceDataHasValue) {
      //value in both fields
      generateResponseBtntooltip = 'askButton.tooltip';
    }
    else if (!userPromptHasValue && !referenceDataHasValue) {
      //no value in both fields
      generateResponseBtntooltip = 'referenceAndActionDataRequired.tooltip';
    } 
    else if (!userPromptHasValue) {
      //no value in user prompt (input data [label show on screen] in case of dual prompt)
      generateResponseBtntooltip = 'actionDataRequired.tooltip';
    }
    else{
      //no value in reference data
      generateResponseBtntooltip = 'referenceDataRequired.tooltip';
    }

  }
  else if (props?.prompt?.SURVEYPROMPT){
    //survey prompt
    if (userPromptHasValue) {
      //value in user prompt
      generateResponseBtntooltip = 'reviewResponse.tooltip';
    } else {
      //no value in user prompt
      generateResponseBtntooltip = 'promptRequiredForResponse.tooltip';
    }
  }
  else if (userPromptHasValue) {
    //value in user prompt
    generateResponseBtntooltip = 'askButton.tooltip';
  }
  else {
    //no value in user prompt
    generateResponseBtntooltip = 'promptRequiredForResponse.tooltip';
  }

  const copyEmbedCode = async () => {
    //const iframeCode = `<iframe src="${window.location.origin}/app/embed/prompt/${props?.prompt?.URLCODE}" sandbox="allow-forms allow-scripts allow-same-origin allow-presentation" width="100%" height="100%" frameborder="0"></iframe>`;
    const iframeCode = `<iframe src="${window.location.origin}/app/askgpt/${props?.prompt?.URLCODE}" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen width="100%" height="100%" frameborder="0"></iframe>`;
    // <iframe width="560" height="315" src="https://gptbluedev.bluecallom.ai/app/embed/prompt/gptblueA4BA54BD-0284-3ADF-2BB35A6BAED70261" title="GPTBlue Prompt" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    try {
      await navigator.clipboard.writeText(iframeCode);
      dispatch(updateAlertMessage({ status: 'success', message: getTranslatedText('embedCodeCopy.message', PageTranslation) }));
    } catch (err) {
      //console.error('Failed to copy text: ', err);
    }
  }

  return (
    <>
      <Card id={props?.cardId}
        titleType={1} 
        cardHeightClass="h-100" 
        title={props?.cardTitle} 
        help={true} 
        Feedback={props.pageType == 'embed' ? false : true} 
        like={params.id && props.pageType != 'embed' ? true : false} 
        share={params.id && props.pageType != 'embed' ? true : false} 
        settings={isHidden || props.pageType == 'embed' ? false : editAccess} 
        settingsClicked={() => navigate(`/app/prompts/edit/${promptId}`)} 
        logo={true} 
        bottomTextFlag={isHidden || props.pageType == 'embed' ? false : true} 
        bottomText={'author.text'}
        bottomAppenderText={bottomAppenderText} 
        helpTitle={props?.cardHelpTitle}
        helpContent={props?.cardHelpContent}
        {...(isHidden || props.pageType == 'embed' ? {} : { additionalFooterIcons: additionalFooterIcons })} 
        //additionalFooterIcons={additionalFooterIcons}
      >
          
        {(!params.id || !props?.prompt || props?.isLibraryTypeChanged) &&
          <div>
            <div className="row">
              <div className="col-12">
                <div className="my-3">
                  {isLoggedIn && userDetailsData?.user?.betaUser 
                  ?
                    <> 
                      <span dangerouslySetInnerHTML={{__html:getTranslatedText('ioSelectPromptloggedIn.message', PageTranslation)}} />
                      {TranslationEditFlag && <EditTranslationIcon translationKey={'ioSelectPromptloggedIn.message'} translationType="text" />}
                    </> 
                  :
                    <>
                      <span dangerouslySetInnerHTML={{__html: getTranslatedText('ioSelectPromptNonloggedIn.message', PageTranslation)}} />
                      {TranslationEditFlag && <EditTranslationIcon translationKey="ioSelectPromptNonloggedIn.message" translationType="text" />}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        <div className={`${params.id ? '' : 'd-none'} ${props?.prompt ? '' : 'd-none'} ${isHidden ? 'd-none': ''}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className={props.pageType == 'embed' ? "col-md-12" : "col-md-7"}>
                <h6 className="cursor-pointer"
                  data-bs-toggle="modal" 
                  data-bs-target='#prompt_name_help'
                >
                  {showBluePrompt && 
                    <img src={Flag} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className='h-1-5 cursor-pointer'/>
                  }
                  <TooltipComponent title={getTranslatedText("promptDetails.tooltip", PageTranslation)}>
                    {props?.prompt?.PROMPTNAME}
                  </TooltipComponent>
                  {TranslationEditFlag && <EditTranslationIcon translationKey="promptDetails.tooltip" translationType="tooltip"/>}
                </h6>
                {props?.prompt?.INSTRUCTIONTOUSER &&
                  <div className="my-2 p-1 overflow-auto maxH-5 text-break scrollbar">
                    {props?.prompt?.INSTRUCTIONTOUSER}
                  </div>
                }
                {props?.prompt?.EXPIREON &&
                  <div className="my-2">
                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('expiryPrompt.text', PageTranslation)}} className="fw-bold" />
                    {TranslationEditFlag && (
                        <EditTranslationIcon translationKey="expiryPrompt.text" translationType='text'/>
                    )}:
                    &nbsp;
                    {props?.prompt?.EXPIREON ? new Date(props?.prompt?.EXPIREON).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }) + ' | ' + new Date(props?.prompt?.EXPIREON).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) : '---'}
                    &nbsp;CET
                  </div>
                }
                {props?.prompt?.DUALPROMPT ==1 && 
                  <div className="mb-3">
                    <label htmlFor="referenceData" className="fw-bold">
                      <span dangerouslySetInnerHTML={{__html: getTranslatedText('referenceData.text', PageTranslation)}} />
                      {TranslationEditFlag && (
                          <EditTranslationIcon translationKey="referenceData.text" translationType='text'/>
                      )}
                    </label>
                    <textarea className="form-control" 
                        id="referenceData" 
                        {...register('DUALPROMPTREFERENCEDATA', { required: true })} 
                        rows={8} maxLength={2500}
                        onChange={e => {
                          setReferenceDataCCount(2500 -e.target.value.length);
                          setValue('DUALPROMPTREFERENCEDATA', e.target.value);
                        }}
                    />
                    <div className="invalid-feedback">
                      {errors.DUALPROMPTREFERENCEDATA && errors.DUALPROMPTREFERENCEDATA.type == 'required' && 
                        <>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('referenceData.required.message', PageTranslation)}} />
                        {TranslationEditFlag && <EditTranslationIcon translationKey="referenceData.required.message" translationType="text" />}
                        </>
                      }
                    </div>
                    <div>
                      <small className="text-muted">
                        {referenceDataCCount}
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('charsRemaining.text', PageTranslation)}} />
                        {TranslationEditFlag && <EditTranslationIcon translationKey="charsRemaining.text" translationType="text" />}
                      </small>
                    </div>
                </div>
                }
                <div className="mb-3 ">
                  {props?.prompt?.DUALPROMPT ==1 &&
                    <label htmlFor="userPrompt" className="fw-bold">
                      {getTranslatedText('actionData.text', PageTranslation)}
                      {TranslationEditFlag && <EditTranslationIcon translationKey="actionData.text" translationType="text" />}
                    </label>
                  }
                  <textarea 
                    className={`form-control scrollbar ${errors?.userPrompt ? 'is-invalid' : ''}`} 
                    id="userPrompt" 
                    {...register('userPrompt', { required: true })} 
                    rows={3} 
                    maxLength={2500}
                    onChange={e => {
                      setUserPromptCCount(2500 -e.target.value.length);
                      setValue('userPrompt', e.target.value);
                    }}
                  />
                  <div className="invalid-feedback">
                    {errors.userPrompt && errors.userPrompt.type == 'required' && 
                    <>
                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('defaultAction.required.message', PageTranslation)}} />
                    {TranslationEditFlag && <EditTranslationIcon translationKey="defaultAction.required.message" translationType="text" />}
                    </>
                    }
                  </div>
                  <small className="form-text text-muted">
                    {userPromptCCount} 
                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('charsRemaining.text', PageTranslation)}} />
                    {TranslationEditFlag && <EditTranslationIcon translationKey="charsRemaining.text" translationType="text" />}
                  </small>
                </div>
                {props.pageType != 'embed' &&
                  <div className="text-center">
                    <div className="mb-2 w-50">
                      <input type="range" className="form-range bc-range" min="0" max="1" step="1" onChange={selectLayoutMode} value={layoutMode}/>
                      <div className="d-flex justify-content-between">
                        <TooltipComponent title={getTranslatedText('simpleMode.tooltip', PageTranslation)}>
                            <small>
                              <span dangerouslySetInnerHTML={{__html: getTranslatedText('simpleMode.text', PageTranslation)}} />
                            </small>
                        </TooltipComponent>
                        {TranslationEditFlag && 
                            <>
                            <EditTranslationIcon translationKey="simpleMode.text" translationType="text"/>
                            <EditTranslationIcon translationKey="simpleMode.tooltip" translationType="text"/>
                            </>
                        }
                        <TooltipComponent title={getTranslatedText('advanceMode.tooltip', PageTranslation)}>
                          <small>
                          <span dangerouslySetInnerHTML={{__html: getTranslatedText('advanceMode.text', PageTranslation)}} />
                          </small>
                        </TooltipComponent>
                        {TranslationEditFlag &&
                          <>
                              <EditTranslationIcon translationKey="advanceMode.text" translationType="text"/>
                              <EditTranslationIcon translationKey="advanceMode.tooltip" translationType="text"/>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                }
                {layoutMode == "1" && props.pageType != 'embed' &&
                  <div className="mb-2 form-check form-switch">
                    <input className="form-check-input" 
                      type="checkbox" 
                      id="authorView"
                      checked={localAuthorView} 
                      {...register('authorView')}
                      onChange={handleChangeAuthorView}  
                    />
                    <label className="form-check-label" htmlFor="authorView">
                      {getTranslatedText('authorView.text', PageTranslation)}
                      {TranslationEditFlag && <EditTranslationIcon translationKey="authorView.text" translationType="text"/>}
                    </label>
                  </div>
                }
              </div>
              {props.pageType != 'embed' &&
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <span>
                        <TooltipComponent title={getTranslatedText('promptProductivity.tooltip', PageTranslation)}>
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('promptProductivity.text', PageTranslation)}} />
                        </TooltipComponent>
                    </span>
                    {TranslationEditFlag && 
                        <>
                        <EditTranslationIcon translationKey="promptProductivity.text" translationType="text"/>
                        <EditTranslationIcon translationKey="promptProductivity.tooltip" translationType="tooltip"/>
                        </>
                    }
                    <div className="input-group input-group-sm">
                      <input type="number" value={gain}
                        step="0.25" id="ManualEffort"
                        className={`form-control form-control-sm text-end ${errors?.ManualEffort ? 'is-invalid' : ''}`}
                        aria-label="gainValue"
                        aria-describedby="gainValue"
                        max = {3000}
                        {...register('ManualEffort', { maxLength: 4 })}
                        onKeyDown={(e) => {
                          if (['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                              return;
                          }
                          let inputValue = Number((e.target as HTMLInputElement).value + e.key);
                          if (inputValue > 3000) {
                              e.preventDefault();
                          }
                        }}
                        onChange={(e) => setGain(Number(e.target.value))}
                      />
                      <span className="input-group-text" id="gainValue" dangerouslySetInnerHTML={{ __html: getTranslatedText('manualHours.text', PageTranslation)}}></span>
                    </div>
                    {TranslationEditFlag &&
                      <EditTranslationIcon translationKey="manualHours.text" translationType="text"/>
                    }
                    <div className="invalid-feedback">
                      {errors.ManualEffort && errors.ManualEffort.type === 'maxLength' && 
                       <>
                       <span dangerouslySetInnerHTML={{__html: getTranslatedText('maxLength4.message', PageTranslation)}} />
                       {TranslationEditFlag && <EditTranslationIcon translationKey="maxLength4.message" translationType="text" />}  
                       </>
                      }
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="pe-1">A</div>
                      <strong>{new Intl.NumberFormat('en-US').format(Math.round(((gain * 60) / 5) * 100) / 100)} x</strong>
                      <div className="ps-1">
                        {getTranslatedText('gain.text', PageTranslation)}
                        {TranslationEditFlag && <EditTranslationIcon translationKey="gain.text" translationType="text"/>}
                      </div>
                    </div>
                  </div>
                </div> 
                <div className='mt-2 text-end'>
                    <img src={props?.prompt?.promptImage} 
                      className={"img-fluid h-7 " + (props?.prompt?.BLUEPROMPT == 1 ? "border border-primary rounded" : (props?.prompt?.OPENSOURCE == 1 ? "border bl_border-orange rounded" : "border border-dark-subtle rounded"))} 
                      alt={getTranslatedText('imageAlt.text', PageTranslation)}
                    />
                </div>  
              </div>
              }
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                {props?.prompt?.OPENSOURCE == 1 && isLoggedIn && layoutMode == "1" && props.pageType != 'embed' &&
                  <div className="d-inline-block">
                    <TooltipComponent title={user?.isProfileComplete ? getTranslatedText('copyPrompt.tooltip', PageTranslation) : getTranslatedText('copyPromptProfileInComplete.tooltip', PageTranslation)} >
                      <button type="button" className={`btn btn-primary btn-md rounded-pill me-2 px-4 ${user?.isProfileComplete ? '' : 'disabled-item'}`} onClick={copyPrompt}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('copyPrompt.text', PageTranslation)}} />
                      </button>
                    </TooltipComponent>
                    {TranslationEditFlag &&
                      <>
                      <EditTranslationIcon translationKey="copyPrompt.text" translationType="text"/>
                      <EditTranslationIcon translationKey={user?.isProfileComplete ? "copyPrompt.tooltip" : "copyPromptProfileInComplete.tooltip"} translationType="tooltip"/>
                      </>
                    }
                  </div>
                } 
                {(isLoggedIn || props?.prompt?.PREMIUMPRICECC==0) 
                ?
                  <div className="d-inline-block">
                    <TooltipComponent title={getTranslatedText(generateResponseBtntooltip, PageTranslation)}>
                      <button 
                        id="savePromptBtn" type="submit" 
                        className="btn btn-primary btn-md rounded-pill px-4"
                        disabled={props?.loading || !userPromptHasValue || (props?.prompt?.DUALPROMPT == 1 && !referenceDataHasValue)}
                        data-bs-toggle={dontShowExeAlert ? undefined : "modal"}
                        data-bs-target={dontShowExeAlert ? undefined : "#promptExecutingConfirmModal"}
                      >
                        {props?.prompt?.SURVEYPROMPT 
                        ?
                          <span dangerouslySetInnerHTML={{__html: getTranslatedText('reviewResponse.text', PageTranslation)}} />
                        : 
                          <span dangerouslySetInnerHTML={{__html: getTranslatedText('submit.text', PageTranslation)}} />
                        }
                      </button>
                    </TooltipComponent>
                    {TranslationEditFlag && 
                        <>
                        <EditTranslationIcon translationKey={props?.prompt?.SURVEYPROMPT ? "reviewResponse.text" : "submit.text"} translationType="text"/>
                        <EditTranslationIcon translationKey={generateResponseBtntooltip} translationType="tooltip"/>
                        </>
                    }
                  </div>
                : 
                  <div className="text-danger">
                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('loginToExecutePrompt.message', PageTranslation)}} />
                    {TranslationEditFlag && <EditTranslationIcon translationKey="loginToExecutePrompt.message" translationType="text"/>}
                  </div>
                }
                <div>
                  <div className='mb-1'>
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('estimatedUsageInCC.text', PageTranslation)}} />
                    {TranslationEditFlag && <EditTranslationIcon translationKey="estimatedUsageInCC.text" translationType="text"/>}
                    :&nbsp;
                    {averageCCUsed == -1 
                    ? 
                    <>
                      <span dangerouslySetInnerHTML={{__html: getTranslatedText('notAvailable.text', PageTranslation)}} />
                      {TranslationEditFlag && <EditTranslationIcon translationKey="notAvailable.text" translationType="text"/>}    
                    </>
                    : 
                    new Intl.NumberFormat('en-US').format(averageCCUsed)}                      
                  </div> 
                </div>
              </div>
            </div>
          </form>
        </div>
        {isLoggedIn && props.pageType != 'embed' &&
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <TooltipComponent title={getTranslatedText('chatMode.tooltip', PageTranslation)} >
                  <button type="button" className="btn btn-primary btn-md rounded-pill px-4" onClick={() => navigate('/app/chatMode')}>
                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('chatMode.text', PageTranslation)}} />
                  </button>
                </TooltipComponent>
                {TranslationEditFlag &&
                    <>
                        <EditTranslationIcon translationKey="chatMode.tooltip" translationType="tooltip"/>
                        <EditTranslationIcon translationKey="chatMode.text" translationType="text"/>
                    </>
                } 
              </div>
            </div>
          </div>
        }
        {props.pageType != 'embed' &&
        <div className={`row ${params.id && props?.prompt && !isHidden ? '' : 'd-none'}`}>
          <div className="col-md-12 text-end">
            {props?.prompt?.PROMPTMYPERSONALLIB === 1 &&
            <div className="d-inline-block text-center px-2">
              <TooltipComponent title={props?.prompt?.MYROLEID == 1 ? getTranslatedText('authorRemovePromptFromLibrary.tooltip', PageTranslation) : getTranslatedText('userRemovePromptFromLibrary.tooltip', PageTranslation)} >
                  <div className="mb-1">
                      <small>{getTranslatedText('remove.text', PageTranslation)}</small>
                  </div>
                  <img src={Delete} 
                    alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                    className={'h-1-5 ' + (props?.prompt?.MYROLEID == 1 ? '' : ' cursor-pointer')}
                    data-bs-toggle={props?.prompt?.MYROLEID == 1 ? undefined : "modal"}
                    data-bs-target={props?.prompt?.MYROLEID == 1 ? undefined : "#removeDownloadedPromptConfirmModal"}
                  />
              </TooltipComponent>
              {TranslationEditFlag &&
                <>
                <EditTranslationIcon translationKey="remove.text" translationType="text"/>
                <EditTranslationIcon translationKey={props?.prompt?.MYROLEID === 1 ? "authorRemovePromptFromLibrary.tooltip" : "userRemovePromptFromLibrary.tooltip"} translationType="tooltip"/>
                </>
              }
            </div>
            }
            {isAllowEmbedScript &&
              <div className="d-inline-block text-center px-2">
                <TooltipComponent title={getTranslatedText('copyEmbedScript.tooltip', PageTranslation)} >
                    <div className="mb-1">
                        <small>{getTranslatedText('embed.text', PageTranslation)}</small>
                    </div>
                    <img src={Embed} 
                      alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                      className='h-1-5 cursor-pointer'
                      onClick={copyEmbedCode} 
                    />
                </TooltipComponent>
                {TranslationEditFlag &&
                  <>
                  <EditTranslationIcon translationKey="embed.text" translationType="text"/>
                  <EditTranslationIcon translationKey="copyEmbedScript.tooltip" translationType="tooltip"/>
                  </>
                }
              </div>
            }
          </div>
        </div>
        }
      </Card >
      <PromptAdditionalInfoModal
        id="prompt_name_help"
        title={props?.prompt?.PROMPTNAME || ''}
        description={props?.prompt?.PROMPTDESCRIPTION || ''}
        isBluePrompt={props?.prompt?.BLUEPROMPT === 1 || false}
        isOpenSourcePrompt={props?.prompt?.OPENSOURCE === 1 || false}
      />
      <WelcomeNonLoginCard id="welcomeNonLoginCardModal" 
        showWelcomeCard={showWelcomeCard} 
        referalKey={props?.prompt?.OWNERREFERRALID}
        promptAuthorName={props?.prompt?.OWNERFIRSTNAME ? props?.prompt?.OWNERFIRSTNAME +' '+ props?.prompt?.OWNERLASTNAME : ''}
      />
      <ConfirmationPopup id="promptExecutingConfirmModal" 
        title='promptExecution.modal.title'
        owner={props?.prompt?.OWNERFIRSTNAME + " " + props?.prompt?.OWNERLASTNAME}
        ccUsed={averageCCUsed}
        // content={averageCCUsed == 0 || averageCCUsed == -1 
        // ? 
        //   props?.prompt?.OWNERFIRSTNAME + " " + props?.prompt?.OWNERLASTNAME + getTranslatedText('ccNotAvailablePromptExecutionConfirmation.modal.content', PageTranslation)  
        // : 
        //   `${props?.prompt?.OWNERFIRSTNAME} ${props?.prompt?.OWNERLASTNAME} ${getTranslatedText('ccAvailablePromptExecutionConfirmation.modal.content', PageTranslation)} ${averageCCUsed}`
        // }
        content={averageCCUsed == 0 || averageCCUsed == -1 
          ? 
            'ccNotAvailablePromptExecutionConfirmation.modal.content'  
          : 
            'ccAvailablePromptExecutionConfirmation.modal.content'
        }
        onConfirm={handleConfirmPromptExecution} 
        // onAbort={handleAbortPromptExecution}
        setDoNotAsk={setDontShowExeAlert} 
      />
      <ConfirmationPopup id="removeDownloadedPromptConfirmModal" 
        title="removePromptConfirmation.modal.title" 
        content="removePromptConfirmation.modal.content"
        onConfirm={handleConfirmDeleteDownloadedPrompt}
      />
    </>
  )
}

export default PromptExecution;