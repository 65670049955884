import React, { useEffect } from 'react';
import usePageTranslation from '../../../hooks/usePageTranslation';
import { useSelector } from 'react-redux';
import { getTranslatedText, initializeModal } from '../../../util/util';
import EditTranslationIcon from '../edit-translation-icon/EditTranslationIcon';
import TooltipComponent from '../bootstrap-component/tooltip-component';

interface PromptAdditionalInfoModalProps {
    id: string;
    title: string;
    description: string;
    isBluePrompt?: boolean;
    isOpenSourcePrompt?: boolean;
}

const PromptAdditionalInfoModal: React.FC<PromptAdditionalInfoModalProps> = (props) => {
    const PageTranslation = usePageTranslation();
    const { TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
    
    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(props?.id);
    
        return () => {
          // Call the initializeModal function on unmount/cleanup
          initializeModal(props?.id);
        };
    }, [props?.id]);

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={props?.id} tabIndex={-1} aria-labelledby={`${props?.id}Label`} aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={`${props?.id}Label`}>
                        {props?.title || ''}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div className="modal-body">
                    {props?.description &&
                        <p>{props?.description}</p>
                    }
                    {props?.isOpenSourcePrompt && (
                        <>
                            <div dangerouslySetInnerHTML={{ __html: getTranslatedText("openSourcePromptInfo.message", PageTranslation)}}></div>
                            {TranslationEditFlag && (
                            <EditTranslationIcon translationKey="openSourcePromptInfo.message" translationType='text' />
                        )}
                        </>
                    )}
                    {props?.isBluePrompt && (
                        <> 
                            <div className='mt-3' dangerouslySetInnerHTML={{ __html: getTranslatedText("bluePromptInfo.message", PageTranslation)}}></div>
                            {TranslationEditFlag && (
                                <EditTranslationIcon translationKey="bluePromptInfo.message" translationType='text' />
                            )}
                        </>
                    )}
                </div>
                <div className="modal-footer">
                    <TooltipComponent title={getTranslatedText("close.tooltip", PageTranslation)}>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        {getTranslatedText("close.text", PageTranslation)}
                        </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                        <EditTranslationIcon translationKey="close.text" />
                        <EditTranslationIcon translationKey="close.tooltip" translationType="tooltip" />
                        </>
                    )}
                </div>
            </div>
            </div>
        </div>
    );
};

export default PromptAdditionalInfoModal;