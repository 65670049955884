import React, { useEffect, useState } from 'react';
import Like from '../../../assets/icons/like.svg';
import LikeFilled from '../../../assets/icons/like-filled.svg';
import Share from '../../../assets/icons/share.svg';
import Bell from '../../../assets/icons/thin-bell.svg';
import Logo from '../../../assets/images/logo.png';
import Settings from '../../../assets/icons/settings.svg';
import Settings_red from '../../../assets/icons/settings-red.svg';
import Help from '../../../assets/icons/help.svg';
import FeedbackIcon from '../../../assets/icons/feedback.svg';
import Logout from '../../../assets/icons/logout.svg';
import Home from '../../../assets/icons/home.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRateCardMutation } from '../../../api-integration/public/public';
import { updateAlertMessage, updateIsSessionExpired, updateLibraryType, updateReloadPageAfterSessionExpired, updateUser } from '../../../api-integration/commonSlice';
import TooltipComponent from '../bootstrap-component/tooltip-component';
import SocialShare from '../modal/social-share';
import AddFeedbackModal from '../../secure/modals/add-feedback';
import EditTranslationIcon from '../edit-translation-icon/EditTranslationIcon';
import usePageTranslation from '../../../hooks/usePageTranslation';
import { getTranslatedText } from '../../../util/util';
const HelpModal = React.lazy(() => import('../help-modal/help-modal'));

interface IconProps {
  src: string;
  tooltip: string;
  onClick: () => void;
}

interface Props {
  id: string,
  children?: any,
  title?: string,
  titleType?: number,
  titleAppenderText?: string, //This is used to append text to the title, translation key is not required for this
  cardHeightClass?: string,
  cardGlow?: boolean,
  cardPadding?: string,
  like?: boolean,
  share?: boolean,
  notification?: boolean,
  home?: boolean;
  homeDisabled?: boolean;
  logo?: boolean,
  settings?: boolean,
  settingsDisabled?: boolean,
  help?: boolean,
  Feedback?: boolean,
  logout?: boolean,
  helpTitle?: string,
  bottomTextFlag?: boolean,
  bottomText?: string,
  bottomAppenderText?: string, //This is used to append text to the bottomText, translation key is not required for this
  helpContent?: string,
  onLogout?: () => void,
  settingsClicked?: () => void,
  additionalFooterIcons?: IconProps[];
}

const Card = (props: Props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const feedbackModalId = 'feedbackModal_'+props?.id;
  const { protocol, host } = window.location;
  const PageTranslation = usePageTranslation();
  const navigate = useNavigate();
  const helpModalId = `${props.id}_help`;
  const socialShareModalId = `${props.id}_socialShare`;
  const [liked, setLiked] = useState<boolean>(false);
  const [rateCardPayload, setRateCardPayload] = useState<any>()
  const [functionToCallAfterRefresh, setFunctionToCallAfterRefresh] = useState<any>('')
  const { children, title, titleType, titleAppenderText, cardHeightClass, cardGlow, cardPadding, like, share, notification, logo, settings, help, home, Feedback, logout, settingsDisabled, onLogout, bottomTextFlag, bottomText, bottomAppenderText, additionalFooterIcons } = props;
  const {  TileInfo, TranslationEditFlag } = useSelector((state: any) => state.commonSlice);
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const { isSessionExpired, user} = useSelector((state: any) => state.commonSlice);
  const [localConstTileCode, setLocalConstTileCode] = useState<string>('');
  const [localConstTileShareTitle, setLocalConstTileShareTitle] = useState<string>('');
  const [localConstTileShareDesc, setLocalConstTileShareDesc] = useState<string>('');
  const [localConstTileShareUrl, setLocalConstTileShareUrl] = useState<string>('');
  const [localConstTileShareImageUrl, setLocalConstTileShareImageUrl] = useState<string>('');
  const [localParamId, setLocalParamId] = useState<string>('');
  const [cogWheelTooltip, setCogWheelTooltip] = useState<string>('edit.card.tooltip	');
  const [localConstPageName, setLocalConstPageName] = useState<string>('');
  const [localConstCardName, setLocalConstCardName] = useState<string>('');
  const [numberOfNotifications, setNumberOfNotifications] = useState<string>('0');
  const [showNotificationBell, setShowNotificationBell] = useState<boolean>(false);

  const [rateCardAPI, { data: rateCardResposne, isLoading: isRateCardLoading, isSuccess: isRateCardSuccess, isError: isRateCardError, error: rateCardError }] =
    useRateCardMutation();

  const settingsClicked = () => {
    if (props.settingsClicked && typeof props.settingsClicked === 'function') {
      props.settingsClicked();
    }
  }

  const RateCard = (data: number) => {
    const payload = {
      "tileCode": props?.id,
      "userRating": data
    }
    setRateCardPayload(payload);
    rateCardAPI(payload);
  }

  useEffect(() => {
    if(user?.isProfileComplete == false && props?.id == 'user_profile') {
      //setCogWheelColor('var(--bs-danger)');
      setCogWheelTooltip("completeProfile.card.tooltip");
    }
    else{
      //setCogWheelColor('var(--bs-secondary)');
      setCogWheelTooltip("edit.card.tooltip");
    }
  }, [user?.isProfileComplete]);

  useEffect(() => {
    if(user?.userNotifications && user?.userNotifications?.length == 0) {
      setNumberOfNotifications('0');
      setShowNotificationBell(false);
    }
    else if(user?.userNotifications && user?.userNotifications?.length > 0 && user?.userNotifications?.length < 100) {
      setNumberOfNotifications(user?.userNotifications?.length.toString());
      setShowNotificationBell(true);
    }
    else if(user?.userNotifications && user?.userNotifications?.length >= 100) {
      setNumberOfNotifications("99+");
      setShowNotificationBell(true);
    }
    else {
      setNumberOfNotifications('0');
      setShowNotificationBell(false);
    }
  }, [user?.userNotifications]);

  useEffect(() => {
    if (params?.id) {
      setLocalParamId(params?.id);
    }
    else {
      setLocalParamId('');
    }
  }, [params?.id]);

  useEffect(() => {
    if (isRateCardSuccess) {
      if (rateCardResposne?.statusCode == 200 && rateCardResposne?.success == true) {
        setLiked(!liked)
      }
      else if (rateCardResposne?.statusCode == 401) {
        dispatch(updateIsSessionExpired(true));
        dispatch(updateReloadPageAfterSessionExpired(false));
        setFunctionToCallAfterRefresh('rate');
      }
      else {
        dispatch(updateAlertMessage({ status: 'error', message: rateCardResposne?.message }));
      }
    }
    if (isRateCardError) {
      dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('errorRatingCard.message',PageTranslation) }));
    }
  }, [isRateCardSuccess, isRateCardLoading, isRateCardError]);

  useEffect(() => {
    if (isSessionExpired == false && functionToCallAfterRefresh != '') {
      if (functionToCallAfterRefresh == 'rate') rateCardAPI(rateCardPayload);
    }
  }, [isSessionExpired]);

  useEffect(() => {
    TileInfo?.map((item: any, i: number) => {
      if (item?.tileCode == props?.id) {
        setLiked(item?.tileRating == 1 ? true : false);
        setLocalConstPageName(item?.tilePage);
        setLocalConstCardName(item?.tileName);
        setLocalConstTileCode(item?.tileCode);
        setLocalConstTileShareTitle(item?.shareTitle);
        setLocalConstTileShareDesc(item?.shareDescription);
        setLocalConstTileShareUrl(`${protocol}//${host}${item?.shareURL}`);
        setLocalConstTileShareImageUrl(`${protocol}//${host}${item?.shareImageURL}`);
      }
    });
  }, [props?.id, TileInfo]);
  

  return (
    <>
      <div className={`card ${cardGlow ? 'bc-card-glow' : ''} ${cardHeightClass ? cardHeightClass : ''}`} id={props?.id}>
        <div className="card-head">
          <div className='row g-1'>
            <div className={
              !(like || share || notification)? 'col-12' :
                notification ? 'col-7' : 'col-8'}>
              {
                title &&
                <>
                  {
                    titleType === 1 && (
                      <div className="d-flex align-items-center">
                        <span className="card-title bc-line-before ms-3" dangerouslySetInnerHTML={{ __html: getTranslatedText(title, PageTranslation) }} />
                        {TranslationEditFlag && (
                          <EditTranslationIcon translationKey={title} />
                        )}
                        {titleAppenderText && <span className="ms-1 mb-2">| {titleAppenderText}</span>}
                      </div>
                    )
                  }
                  {
                    titleType == 2 && (
                    <div className="d-flex align-items-center">
                        <span dangerouslySetInnerHTML={{ __html: getTranslatedText(title, PageTranslation) }} />
                        {TranslationEditFlag && (
                          <EditTranslationIcon translationKey={title} />
                        )}
                        {titleAppenderText && <span className="ms-1">| {titleAppenderText}</span>}
                      </div>
                    )
                  }
                </>
              }
            </div>
            {
              (like || share || notification) &&
              <div className={`text-end ${notification ? 'col-5' : 'col-4'}`}>
                {like && isLoggedIn &&
                  <div className="d-inline-block">
                    <TooltipComponent title={liked ? getTranslatedText("dislike.card.tooltip", PageTranslation) : getTranslatedText("like.card.tooltip", PageTranslation)} >
                      <img src={liked ? LikeFilled : Like} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className={`icon cursor-pointer ${isRateCardLoading ? 'disabled-item' : ''}`} onClick={() => RateCard(liked ? 0 : 1)} />
                    </TooltipComponent>
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey={liked ? "dislike.card.tooltip" : "like.card.tooltip"} translationType="tooltip" />
                    )}
                  </div>
                }
                {notification && showNotificationBell &&
                  <div className="d-inline-block me-2 ">
                    <TooltipComponent title={getTranslatedText("notification.card.tooltip", PageTranslation)} >
                      <div className="position-relative">
                        <img 
                          src={Bell} 
                          alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                          className='cursor-pointer'
                          data-bs-container="body"
                          data-bs-toggle="popover"  
                        />
                        <span className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                          data-bs-container="body"
                          data-bs-toggle="popover" 
                        >
                          {numberOfNotifications}
                          <span className="visually-hidden">unread messages</span>
                        </span>
                      </div>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey="notification.card.tooltip" translationType="tooltip" />
                    )}
                    </div>
                }
                {share &&
                  <div className="d-inline-block">
                    <TooltipComponent title={getTranslatedText("socialShare.card.tooltip", PageTranslation)} >
                      <img src={Share} className='icon cursor-pointer' 
                        alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                        data-bs-toggle="modal" 
                        data-bs-target={`#${socialShareModalId}`}
                      />
                    </TooltipComponent>
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey="socialShare.card.tooltip" translationType="tooltip" />
                    )}
                  </div>
                }
              </div>
            }
          </div>
        </div>

        <div className={`card-body ${cardPadding ? props.cardPadding : 'p-3'}`}>
          {children}
        </div>
        {
          (settings || help || Feedback || logout || logo || home) &&
          <div className="card-footer">
            <div className='d-flex justify-content-between'>
              <div>
                {isLoggedIn && Feedback && 
                  <div className="d-inline-block">
                    <TooltipComponent title={getTranslatedText("feedback.card.tooltip", PageTranslation)} >
                      <img src={FeedbackIcon} 
                        alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                        className='icon cursor-pointer' 
                        data-bs-toggle="modal" 
                        data-bs-target={`#${feedbackModalId}`} 
                      />
                    </TooltipComponent>
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey="feedback.card.tooltip" translationType="tooltip" />
                    )}
                  </div>}
                {help && 
                <div className="d-inline-block">
                  <TooltipComponent title={getTranslatedText("help.card.tooltip", PageTranslation)} >
                    <img src={Help} className='icon cursor-pointer' 
                      alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                      data-bs-toggle="modal" 
                      data-bs-target={`#${helpModalId}`} 
                    />
                  </TooltipComponent>
                  {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="help.card.tooltip" translationType="tooltip" />
                  )}
                </div>}
                {settings && 
                  <div className="d-inline-block">
                    <TooltipComponent title={getTranslatedText(cogWheelTooltip, PageTranslation)} >
                      {user?.isProfileComplete == false && props?.id == 'user_profile' ? 
                        <img src={Settings_red} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className={`icon cursor-pointer ${settingsDisabled ? 'disabled-item' : ''}`} onClick={settingsClicked} />
                        :
                        <img src={Settings} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className={`icon cursor-pointer ${settingsDisabled ? 'disabled-item' : ''}`} onClick={settingsClicked} /> 
                      }
                    </TooltipComponent>
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey={cogWheelTooltip} translationType="tooltip" />
                    )}
                  </div>
                }
                {additionalFooterIcons && additionalFooterIcons.map((icon:any, index:number) => (
                  <div key={index} className="d-inline-block">
                    {icon.visibility &&
                    <>
                      <TooltipComponent title={getTranslatedText(icon.tooltip, PageTranslation)}>
                        <img src={icon.src} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className='icon cursor-pointer' onClick={icon.onClick} />
                      </TooltipComponent>
                      {TranslationEditFlag && (
                        <EditTranslationIcon translationKey={icon.tooltip} translationType="tooltip" />
                      )}
                    </> 
                    }
                  </div>
                ))}
                {home && 
                  <div className="d-inline-block">
                    <TooltipComponent title={getTranslatedText("home.card.tooltip", PageTranslation)} >
                      <img 
                        src={Home} 
                        alt= {getTranslatedText('imageAlt.text', PageTranslation)}
                        className={`icon cursor-pointer ${props?.homeDisabled ? 'disabled-item' : ''}`} 
                        onClick={() => {
                          // Update State to set library type to 'personal' bcoz CC deduction for prompts listed on home page will be from personal account
                          localStorage.setItem('LibraryType', 'personal');
                          dispatch(updateLibraryType('personal'));
                          navigate('/home')
                        }}
                      />
                    </TooltipComponent>
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey="home.card.tooltip" translationType="tooltip" />
                    )}
                  </div>}
                {logout && 
                  <div className="d-inline-block">
                    <TooltipComponent title={getTranslatedText("logout.card.tooltip", PageTranslation)} >
                      <img src={Logout} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className='icon cursor-pointer ms-3 me-0' onClick={onLogout} />
                    </TooltipComponent>
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey="logout.card.tooltip" translationType="tooltip" />
                    )}
                  </div>
                }
              </div>
              <div >
                {bottomTextFlag && bottomText &&
                  <div className="d-flex align-items-center">
                    <span dangerouslySetInnerHTML={{ __html: getTranslatedText(bottomText, PageTranslation) }} />
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey={bottomText} translationType='text' />
                    )}
                    {bottomAppenderText && <span>: {bottomAppenderText}</span>}
                  </div>
                }
              </div>

              {logo &&
                <span>
                  { user?.blcFlag == true ? 
                    <>
                    <TooltipComponent title={getTranslatedText("logo.card.tooltip", PageTranslation)} >
                      <a href={process.env.REACT_APP_CF_ROOT_URL + '_/apps/AppsBizz/iims/admin/adm_home.cfm'}>
                        <img src={Logo} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className='icon logo cursor-pointer' />
                      </a>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                      <EditTranslationIcon translationKey="logo.card.tooltip" translationType="tooltip" />
                    )}
                    </>
                  :
                    <img src={Logo} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className='icon logo' />
                  }
                </span>
              }
            </div>
          </div>
        }
      </div>
      <HelpModal 
        id={helpModalId}
        title={props?.helpTitle || ""} 
        content={props?.helpContent}
        pageName={localConstPageName}
        cardName={localConstCardName}
        additionalMsg= "support" 
      />
      <SocialShare 
        id={socialShareModalId} 
        shareObjectId={localConstTileCode} 
        contentShareTitle={localConstTileShareTitle} 
        contentShareDesc= {localConstTileShareDesc} 
        contentShareUrl={localConstTileShareUrl} 
        contentShareImageUrl={localConstTileShareImageUrl} 
        contentShareParamId={localParamId}
      />
      <AddFeedbackModal 
        id={feedbackModalId} 
        feedbackTileCode={props?.id} 
        feedbackTilePage={localConstPageName}
      />
    </>
  )
}
export default Card;