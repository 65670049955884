import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TooltipComponent from '../bootstrap-component/tooltip-component';
import { getTranslatedText, initializeModal } from '../../../util/util';
import usePageTranslation from '../../../hooks/usePageTranslation';
import { useSelector } from 'react-redux';
import EditTranslationIcon from '../edit-translation-icon/EditTranslationIcon';

interface WelcomeCardProps {
    id: string;
    showWelcomeCard: boolean;
    referalKey?: string;
    promptAuthorName?: string;
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({ id, showWelcomeCard, referalKey, promptAuthorName }) => {
    const navigate = useNavigate()
    const PageTranslation = usePageTranslation();
    const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
    const [show, setShow] = useState(showWelcomeCard);
  
    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id);
    
        return () => {
          // Call the initializeModal function on unmount/cleanup
          initializeModal(id);
        };
    }, [id]);

    const handleRedirect = ()=>{
        setShow(false)
        return referalKey? navigate(`/referral/${referalKey}`):navigate('/login')
    }
    
    return (
        <div className={`modal modal-draggable ${show ? 'd-block' : ''}`} id={id} tabIndex={-1} data-bs-backdrop="false" aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={`${id}Label`}>
                        <span dangerouslySetInnerHTML={{ __html: getTranslatedText('welcomNote.text',PageTranslation)}} />
                        {TranslationEditFlag && <EditTranslationIcon translationKey='welcomNote.text' translationType='text'/>}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=> setShow(false)}></button>
                </div>
                <div className="modal-body">
                    <p dangerouslySetInnerHTML={{ __html: getTranslatedText('welcomeNonLoggedInUsers1.message',PageTranslation) + (promptAuthorName != '' ? getTranslatedText('promptSharedBy.tooltip',PageTranslation) + promptAuthorName : "")+ getTranslatedText('welcomeNonLoggedInUsers2.message',PageTranslation) }}></p>
                    {}
                </div>
                <div className="modal-footer">
                    <TooltipComponent title={getTranslatedText('register.tooltip',PageTranslation)}>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleRedirect}>
                            <span dangerouslySetInnerHTML={{ __html: getTranslatedText('register.text',PageTranslation) }}></span>
                            </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey='register.text' translationType='text'/>
                            <EditTranslationIcon translationKey='register.tooltip' translationType='tooltip'/>
                        </>
                    )}
                </div>
                </div>
            </div>
        </div>

        
    );
};

export default WelcomeCard;