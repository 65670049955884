import React from 'react';
import { Bar, Pie, Line, Bubble, Doughnut, PolarArea, Radar, Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement, RadialLinearScale } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement, RadialLinearScale);

interface CustomChartProps {
  chartType: 'bar' | 'pie' | 'line' | 'bubble' | 'doughnut' | 'polarArea' | 'radar' | 'scatter' | 'area';
  data: any;
  options?: any;
}

const CustomChart: React.FC<CustomChartProps> = (props) => {
  const getDefaultOptions = (chartType: string) => {
    const commonOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: 'Sample Chart Title',
        },
      },
    };

    switch (chartType) {
      case 'bar':
        return {
          ...commonOptions,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        };
      case 'line':
        return {
          ...commonOptions,
          elements: {
            line: {
              tension: 0.4,
            },
          },
        };
      case 'bubble':
        return {
          ...commonOptions,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        };
      case 'scatter':
        return {
          ...commonOptions,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        };
      case 'radar':
      case 'polarArea':
      case 'doughnut':
      case 'pie':
      default:
        return commonOptions;
    }
  };

  const defaultOptions = getDefaultOptions(props?.chartType);
  const mergedOptions = { ...defaultOptions, ...props?.options };

  const renderChart = () => {
    switch (props?.chartType) {
      case 'bar':
        return <Bar data={props?.data} options={mergedOptions} />;
      case 'pie':
        return <Pie data={props?.data} options={mergedOptions} />;
      case 'line':
        return <Line data={props?.data} options={mergedOptions} />;
      case 'bubble':
        return <Bubble data={props?.data} options={mergedOptions} />;
      case 'doughnut':
        return <Doughnut data={props?.data} options={mergedOptions} />;
      case 'polarArea':
        return <PolarArea data={props?.data} options={mergedOptions} />;
      case 'radar':
        return <Radar data={props?.data} options={mergedOptions} />;
      case 'scatter':
        return <Scatter data={props?.data} options={mergedOptions} />;
      default:
        return null;
    }
  };

  return <div>{renderChart()}</div>;
};

export default CustomChart;