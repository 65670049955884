/**
 *  This file contains the common slice of the Redux store.
 * The common slice contains the common states that are used across the application.
 * This is a part of the Redux store in your application. It uses Redux Toolkit's `createSlice` function to generate a slice of the Redux store. A slice includes a reducer, actions, and action creators. 

* The purpose of this file is to manage a portion of the application's state, specifically the "common" state that is likely shared and used by multiple components or parts of the application. This state includes user information, login status, alert messages, session information, and more. The file also provides action creators, which are functions that components can use to dispatch actions to update this state.

 * This slice contains the following reducers:
 * 1. updateLogin: Updates the isLoggedIn state to true.
 * 2. updateLogout: Updates the isLoggedIn state to false.
 * 3. fullPageLoader: Updates the fullPageLoader state.
 * 4. updateAlertMessage: Updates the alertMessage state.
 * 5. updateIsSessionExpired: Updates the isSessionExpired state.
 * 6. updateIsRefreshTokenExpired: Updates the isRefreshTokenExpired state.
 * 7. updateReloadPageAfterSessionExpired: Updates the reloadPageAfterSessionExpired state.
 * 8. updateUser: Updates the user state.
 * 9. updateCurrentPrompt: Updates the currentPrompt state.
 * 10. updateTileInfo: Updates the TileInfo state.
 * 11. updatePageTranslation: Updates the PageTranslation state.
 * 
 */


import { createSlice } from '@reduxjs/toolkit'
import { commonSliceInterface } from '../interfaces/common/common.interface'

const initialState: commonSliceInterface = {
  isLoggedIn: false,
  fullPageLoader: false,
  alertMessage: { status: '', message: '', timeout: null },
  isSessionExpired: false,
  isRefreshTokenExpired: false,
  reloadPageAfterSessionExpired: true,
  TranslationEditFlag: false,
  user: {
    userEmail: '',
    userId: 0,
    firstName: '',
    lastName: '',
    picPath: '',
    totalCCPoints: '',
    usrCreatedDate: '',
    referralId: '',
    //roleInOrg: '',
    //blcUsercount: 0,
    //GPTBlueID: 0,
    blcFlag: false,
    accId: 0,
    orgId: 0,
    accountType: '',
  },
  currentPrompt: {},
  TileInfo: [],
  PageTranslation: [],
  PageMemory: [],
  LibraryType: 'personal',
}

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    updateLogin: (state) => {
      state.isLoggedIn = true;
    },
    updateLogout: (state) => {
      state.isLoggedIn = false;
    },
    fullPageLoader: (state, action) => {
      state.fullPageLoader = action.payload;
    },
    updateAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
    updateIsSessionExpired: (state, action) => {
      state.isSessionExpired = action.payload;
    },
    updateIsRefreshTokenExpired: (state, action) => {
      state.isRefreshTokenExpired = action.payload;
    },
    updateReloadPageAfterSessionExpired: (state, action) => {
      state.reloadPageAfterSessionExpired = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateCurrentPrompt: (state, action) => {
      state.currentPrompt = action.payload;
    },
    updateTileInfo: (state, action) => {
      state.TileInfo = action.payload;
    },
    updatePageTranslation: (state, action) => {
      state.PageTranslation = action.payload;
    },
    updatePageMemory: (state, action) => {
      state.PageMemory = action.payload;
    },
    updateLibraryType: (state, action) => {
      state.LibraryType = action.payload;
    },
    updateTranslationEditFlag: (state, action) => {
      state.TranslationEditFlag = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  updateLogin, 
  updateLogout, 
  fullPageLoader, 
  updateAlertMessage, 
  updateIsSessionExpired, 
  updateIsRefreshTokenExpired, 
  updateReloadPageAfterSessionExpired, 
  updateUser, updateCurrentPrompt, 
  updateTileInfo,
  updatePageTranslation,
  updatePageMemory,
  updateLibraryType,
  updateTranslationEditFlag
} = commonSlice.actions

// export default commonSlice.reducer