import React from 'react';
import UserCard from '../../../components/common/user-card/user-card';
import LibraryDetailedList from '../../../components/common/library-detailed-list/library-detailed-list';

const ExchangeList: React.FC = () => {


    return (
        <div className='container'>
            <div className="row">
                <div className="bc-column-3 order-lg-1 order-2">
                    <UserCard />
                </div>
                <div className="bc-column-9 order-lg-2 order-1">
                    <LibraryDetailedList 
                        cardId="exchangeList_list"
                        cardTitle='exchangeListList.card.title'
                        cardHelpTitle='exchangeListList.cardHelp.title'
                        cardHelpContent='exchangeListList.cardHelp.content'
                        libraryType="exchange"
                    />
                </div>
            </div>
        </div>
    );
};

export default ExchangeList;