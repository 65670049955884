import React, { useEffect } from 'react';
import { getTranslatedText, initializeModal } from '../../../util/util';
import TooltipComponent from '../bootstrap-component/tooltip-component';
import EditTranslationIcon from '../edit-translation-icon/EditTranslationIcon';
import { useSelector } from 'react-redux';
import usePageTranslation from '../../../hooks/usePageTranslation';

interface SendCommentModalProps {
    id: string;
    onSendComment: () => void;
    commentError: string;
    setCommentError: (error: string) => void;
    userComment: string;
    setUserComment: (comment: string) => void;
}

const SendCommentModal: React.FC<SendCommentModalProps> = ({ id, onSendComment,commentError, setCommentError, userComment, setUserComment }) => {
    const PageTranslation = usePageTranslation();
    const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id, resetModalValues);
    
        return () => {
          // Call the initializeModal function on unmount/cleanup
          initializeModal(id, resetModalValues);
        };
    }, [id]); 

    const resetModalValues = () => {
        setUserComment("");
        setCommentError("");
    }

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            {/* with modal-dialog style={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}} */}
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={`${id}Label`}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('addComment.text',PageTranslation)}}/>
                        {TranslationEditFlag && <EditTranslationIcon translationKey='addComment.text' translationType='text'/>}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {TranslationEditFlag && <EditTranslationIcon translationKey='comment.placeholder' translationType='placeholder'/>}
                    <input type="text" 
                        className={`form-control form-control-lg ${commentError ? 'is-invalid' : ''}`} 
                        value={userComment}
                        placeholder={getTranslatedText('comment.placeholder',PageTranslation)}
                        onChange={(e: any) => {
                            setUserComment(e.target.value);
                            if (e.target.value.length > 0 && e.target.value.length < 500) {
                                setCommentError("");
                            }
                            else if(e.target.value.length > 500) {
                                setCommentError('maxlength500.message');
                            }
                            else if(e.target.value.length == 0) {
                                setCommentError('comment.required.message');
                            }
                        }} 
                    />
                    {commentError.length > 0 && 
                        <div className="invalid-feedback">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText(commentError,PageTranslation)}}/>
                            {TranslationEditFlag && <EditTranslationIcon translationKey={commentError as string} translationType='text'/>}
                        </div>
                    }
                </div>
                <div className="modal-footer">
                    <TooltipComponent title={getTranslatedText('close.tooltip',PageTranslation)}>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('close.text',PageTranslation)}}/>
                    </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey='close.text' translationType='text'/>
                            <EditTranslationIcon translationKey='buttons.send.tooltip' translationType='tooltip'/>
                        </>
                    )}
                    <TooltipComponent title={getTranslatedText('send.tooltip',PageTranslation)}>
                    <button type="button" className="btn btn-primary"
                        onClick={onSendComment}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('send.text',PageTranslation)}}/>
                    </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey='send.text' translationType='text'/>
                            <EditTranslationIcon translationKey='send.tooltip' translationType='tooltip'/>
                        </>
                    )}
                </div>
                </div>
            </div>
        </div>
    );
};

export default SendCommentModal;