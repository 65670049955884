import { Modal } from "bootstrap";
import { draggableBootstrapModal } from "../components/common/modal/draggable-modal";
import { NavigateFunction } from 'react-router-dom';

const markdownit = require('markdown-it');

type PageSetting = {
  USERPAGESETTINGID: number;
  PAGESETTINGDATE: string;
  PAGESETTINGKEY: string;
  PAGESETTINGVALUE: string;
};

export const getCookie = (name: string) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  if (match) {
    return match[2]
  }
}
export const clearCookies = (name: string) => {
  document.cookie = name + '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const ProcessingMessageKeyValues = [
  'processingMsg1.message',
  'processingMsg2.message',
  'processingMsg3.message'
];

export const convertMarkdownToHtml = (markdown: any, rootClass: any) => {
  var md = markdownit();
  var htmlContent = md.render(markdown);

  var textSizeClass = '';
  switch (rootClass) {
    case 'stdTextS':
      textSizeClass = 'stdTextS';
      break;
    case 'stdText':
      textSizeClass = 'stdText';
      break;
    case 'stdTextL':
      textSizeClass = 'stdTextL';
      break;
    case 'stdTextXL':
      textSizeClass = 'stdTextXL';
      break;
    default:
      textSizeClass = 'stdText';
  }

  // Replace <strong> tags with Bootstrap classes
  htmlContent = htmlContent.replace(/<strong>(.*?)<\/strong>/g, '<strong class="font-weight-bold">$1</strong>');

  // Apply root class to <p> and <li> elements
  htmlContent = htmlContent.replace(/<p>/g, `<p class="${textSizeClass}">`);
  htmlContent = htmlContent.replace(/<li>/g, `<li class="${textSizeClass}">`);

  // Add "markdownTableBLC" class to all <table> elements
  htmlContent = htmlContent.replace(/<table/g, `<table class="markdownTableBLC table ${textSizeClass}"`);

  // Apply root class to <th> elements within <table>
  htmlContent = htmlContent.replace(/<th>/g, `<th class="${textSizeClass}">`);

  // Apply root class to <td> elements within <table>
  htmlContent = htmlContent.replace(/<td>/g, `<td class="${textSizeClass}">`);

  // Remove escaped HTML tags
  htmlContent = htmlContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"');

  // Remove escaped HTML tags and specified tags
  htmlContent = htmlContent.replace(/&lt;pre id=&quot;markdownTableBLC&quot;&gt;/g, '').replace(/&lt;\/pre&gt;/g, '');
  htmlContent = htmlContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"');
  return htmlContent;
}


export const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName('grecaptcha-badge')[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? 'visible' : 'hidden';
  }
};


export const FormatDate = (currentDate: any) => {
  currentDate = new Date(currentDate);
  // Get the month name
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const monthName = monthNames[currentDate.getMonth()];

  // Get the day and year
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  // Format the date string
  const formattedDate = `${monthName}, ${day}/${year}`;

  // Display the formatted date
  return formattedDate
}

export const getPageByURL = (url: string): string | undefined => {
  const pages: { [key: string]: string } = {
    // Public pages (App.tsx)
    '/login/': 'login',
    '/forgotpwd/': 'forgotPassword',
    '/home/': 'home',
    '/app/askgpt/': 'askgpt', //it is for embed prompt
    '/app/io/': 'io',
    '/app/exchangeHome/': 'exchangeHome',
    '/app/exchangeList/': 'exchangeList',
    '/app/embed/library/': 'embedLibrary',
    '/referral/': 'referral',
    '/company/': 'home',
    '/unsubscribe/': 'unsubscribe',

    // Secure pages (secure.tsx)
    '/app/prompts/create/': 'dtd',
    '/app/prompts/edit/': 'dtd',
    '/app/prompts/version/': 'promptVersionHistory',
    '/app/prompts/result/': 'promptResultsHistory',
    '/app/prompts/approval/': 'promptManager',
    '/app/userProfileEdit/': 'userProfile',
    '/app/accounting/': 'accounting',
    '/app/corpLibrary/': 'corpLibraryHome',
    '/app/corpLibraryList/': 'corpLibraryList',
    '/app/orgCoinManagement/': 'organizationCoinManagement',
    '/app/PaymentNewCard/': 'cardPayment',//Not used
    '/app/payout/': 'payout',
    '/app/changePwd/': 'changePassword',
    '/app/manageTeam/': 'teamManagement',
    '/app/manageCorp/': 'corpManagement',
    '/app/manageOrg/': 'organizationManagement',
    '/app/requestCompany/': 'requestCompany',
    '/app/manageCustomModel/': 'manageCustomModel',
    '/app/customModel/': 'customModel',//Not used
    '/app/chatMode/': 'chatMode',
    '/app/approveAccRequest/': 'approveAccount',
    '/app/internalAdminPortal/': 'InternalAdminHome',
    '/app/manageLibrary/': 'libraryManagement',
    '/app/myFeedback/': 'myFeedback',
    '/app/manageFeedback/': 'ManageUserFeedback',
    '/app/myMessages/': 'myMessages',
    '/app/myBadges/': 'myBadges',
    '/app/myPreferences/': 'userPreferences',
    '/app/manageRoles/': 'adminRoleRights',
    '/app/manageSegments/': 'adminSegments',
    '/app/manageMailServer/': 'manageMailServer',
    '/app/adminBadges/': 'adminBadges',
    '/app/manageTranslation/': 'adminTranslation',
    '/app/translationList/': 'adminTranslationList',
    '/app/adminLibraryApproval/': 'adminExchange',
    '/app/adminPayoutApproval/': 'adminPayout',
    '/app/managePartner/': 'managePartner',
    '/app/manageCompany/': 'manageCompany',
    'app/partnerRequest/': 'partnerRequest',
    '/app/partnerHome/': 'partnerHome',
    '/app/partnerProgramsList/': 'partnerProgramList',
    '/app/myNetwork/': 'myNetwork',
    '/app/agentStudio/': 'agentStudio',
    '/app/assetIo/': 'assetIo',
    '/app/solutionStudio/': 'solutionStudio',
    '/app/solutionIo/': 'solutionIo',
    '/app/launchpad/': 'blueCallomLaunchpad',
    '/app/manageDBConnection/': 'manageDBConnection',
    '/app/manageDBQueryFunction/': 'manageDBQueryFunction',
  };

  // Ensure the URL has a trailing slash
  if (!url.endsWith('/')) {
    url += '/';
  }
  
  for (let key in pages) {
    if (url.includes(key)) {
      return pages[key];
    }
  }

  //function will only return a value if the URL exactly matches one of the keys in the pages object
  // for (let key in pages) {
  //   if (url === key) {
  //     return pages[key];
  //   }
  // }
}

export const capitalizeFirstLetter = (string:string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const convertToThousands = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const initializeModal = (id: string, clearErrors?: () => void, reset?: () => void, resetModalValues?: () => void) => {
  const modalElement = document.getElementById(id);
  if (modalElement) {
    draggableBootstrapModal(modalElement);

    const handleModalHidden = () => {
      if (reset){
        reset();
      }
      if (clearErrors){
        clearErrors();
      }
      if (resetModalValues) {
        resetModalValues();
      }
    };

    modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
  }
};

export const hideModal = (id: string) => {
  const myModalEl = document.getElementById(id);
  if (myModalEl) {
    const modal = Modal.getInstance(myModalEl);
    if (modal) {
      modal?.hide();
    }
  }
};

// Function to get the translated text from the translation key
export const getTranslatedText = (translationKey: string, PageTranslation: any[], contentFormat: string = 'plain'): string => {
  if (!Array.isArray(PageTranslation)) {
    //return `${translationKey}`;
    return '';
  }
 
  for (const item of PageTranslation) {
    // if (item.translationKey === translationKey) {
    //   return item.translation[0].TRANSLATEDTEXT;
    // }
    if (item.translationKey === translationKey) {
      let translatedText = item.translation[0].TRANSLATEDTEXT;
      //if (contentFormat === 'plain') {
      if (item.translationContentFormat === 'plain') {
        // Remove <p> tags from the translated text
        //translatedText = translatedText.replace(/<p>/g, '').replace(/<\/p>/g, '');
        // Remove all HTML tags from the translated text
        translatedText = translatedText.replace(/<\/?[^>]+(>|$)/g, '');
      } 
      else if (item.translationContentFormat === 'html') {
        // Add class to <p> tags in the translated text
        translatedText = translatedText.replace(/<p>/g, '<p class="mb-0">');
      }
      return translatedText;
    }

  }
  return `${translationKey}`;
};

// Function to get the remaining characters
export const getRemainingCharacters = (maxLength: number, currentLength: number): number => {
  return Math.max(0, maxLength - currentLength);
};

// Function to navigate to the manage translation page
export const navigateToTranslation = (navigate: NavigateFunction, passedTranslationKey: string) => {
  navigate('/app/manageTranslation', { state: { passedTranslationKey } });
};

//function to get the page setting value
export const getPageSettingValue = (settings: PageSetting[], key: string): string | undefined => {
  const setting = settings.find(setting => setting.PAGESETTINGKEY === key);
  return setting ? setting.PAGESETTINGVALUE : undefined;
};

//function to get popover content
export const generatePopoverContent = (notifications: { NOTIFICATION: string, REDIRECTURL: string, OBJTYPE: string, NOTIFICATIONAGE: string, OBJID: number }[]): string => {
  return `
    <div>Notifications</div>
    ${notifications && notifications.map(notification => `
      <div class="d-flex justify-content-between mb-2">
        <div >
          <a class="text-decoration-none" href="${notification.REDIRECTURL}${notification.REDIRECTURL.includes('?') ? '&' : '?'}objId=${notification.OBJID}">
            ${notification.NOTIFICATION}
          </a>
        </div>
        <div class="ms-3 text-nowrap">
          ${notification.NOTIFICATIONAGE}
        </div>
      </div>
    `).join('')}
  `;
};

//function to get DNA value
export const extractDnaValue = (dnaString: string, position: number, length: number): string => {
  // Ensure position is zero-based
  const zeroBasedPosition = position - 1;

  // Validate inputs
  // if (zeroBasedPosition < 0) {
  //   throw new Error('Position must be greater than 0');
  // }
  // if (length < 0) {
  //   throw new Error('Length must be non-negative');
  // }
  // if (zeroBasedPosition + length > dnaString.length) {
  //   throw new Error('Position and length exceed the dnaString length');
  // }

  // Extract the substring
  const extractedValue = dnaString.substring(zeroBasedPosition, zeroBasedPosition + length);
  return extractedValue;
};