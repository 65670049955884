import { useEffect, FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateLogin, updateLogout } from '../api-integration/commonSlice';
import PropTypes from 'prop-types';

interface GuestGuardProps {
  children: ReactNode;
}

const NonAuthGuard: FC<GuestGuardProps> = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
    isLoggedIn ? dispatch(updateLogin()) : dispatch(updateLogout());
  }, []);
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  // Check if the current path is related to social network connection
  const isSocialNetworkConnection = location.pathname.includes('/external');
  
  if (!isLoggedIn || isSocialNetworkConnection) {
    return <>{children}</>;
  }
  // if (!isLoggedIn) {
  //   return <>{children}</>;
  // }

  const page = sessionStorage.getItem('page');
  return <Navigate to={page ? page : '/app'} />;
};

NonAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default NonAuthGuard;