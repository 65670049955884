import React, { useEffect, useRef } from 'react';
import usePageTranslation from '../../../hooks/usePageTranslation';
import { getTranslatedText, initializeModal } from '../../../util/util';
import { useSelector } from 'react-redux';
import EditTranslationIcon from '../../common/edit-translation-icon/EditTranslationIcon';
import TooltipComponent from '../../common/bootstrap-component/tooltip-component';

interface CreateRefKeyModalProps {
    id: string;
    title: string;
    content: string;
    onCreateRefKey: () => void;
}

const CreateRefKeyModal: React.FC<CreateRefKeyModalProps> = (props) => {
    const PageTranslation = usePageTranslation();
    const { TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
    
    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(props.id);
    
        return () => {
            // Call the initializeModal function on unmount/cleanup
            initializeModal(props.id);
        };
    }, [props.id]);
    

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={props?.id} tabIndex={-1} aria-labelledby={`${props?.id}Label`} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${props?.id}Label`}>
                            {getTranslatedText(props?.title, PageTranslation)}
                        </h5>
                        {TranslationEditFlag && (
                            <EditTranslationIcon translationKey={props?.title} />
                        )}
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div dangerouslySetInnerHTML={{ __html: getTranslatedText(props?.content, PageTranslation)}}></div>
                        {TranslationEditFlag && (
                            <EditTranslationIcon translationKey={props?.content} />
                        )}
                    </div>
                    <div className="modal-footer">
                        <TooltipComponent title={getTranslatedText("close.tooltip", PageTranslation)}>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            {getTranslatedText("close.text", PageTranslation)}
                            </button>
                        </TooltipComponent>
                        {TranslationEditFlag && (
                            <>
                            <EditTranslationIcon translationKey="close.text" />
                            <EditTranslationIcon translationKey="close.tooltip" translationType="tooltip" />
                            </>
                        )}
                        <TooltipComponent title= {getTranslatedText("refKeyGenerate.tooltip", PageTranslation)} >
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={props?.onCreateRefKey}>
                                {getTranslatedText("refKeyGenerate.text", PageTranslation)}
                            </button>
                        </TooltipComponent>
                        {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey="refKeyGenerate.text" />
                            <EditTranslationIcon translationKey="refKeyGenerate.tooltip" translationType="tooltip" />
                        </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateRefKeyModal;