import React from 'react';
import CustomChart from './custom-chart';

interface ChartData {
  type: 'bar' | 'pie' | 'line' | 'bubble' | 'doughnut' | 'polarArea' | 'radar' | 'scatter';
  data: any;
  options?: any;
}

interface ChartRendererProps {
  content: string;
  chartData: { [key: string]: ChartData };
}

const ChartRenderer: React.FC<ChartRendererProps> = ({ content, chartData }) => {
  const renderCharts = (text: string): React.ReactNode[] => {
    const chartPlaceholders = (text.match(/{{CHART_\d+}}/g) || []) as string[];
    const parts = text.split(/({{CHART_\d+}})/g);

    return parts.map((part, index) => {
      if (chartPlaceholders.includes(part)) {
        const chartId = part.replace(/[{}]/g, '');
        const chartInfo = chartData[chartId];
        if (chartInfo) {
          return (
            <div 
              key={index} id={chartId} 
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                maxWidth: '500px',
                height: 'auto',
                margin: '0 auto',
              }}
            >
              <CustomChart
                chartType={chartInfo.type}
                data={chartInfo.data}
                options={chartInfo.options}
              />
            </div>
          );
        }
      }
      //return <React.Fragment key={index}>{part}</React.Fragment>;
      return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    });
  };

  const renderedContent = renderCharts(content);

  return <div>{renderedContent}</div>;
  
};

export default ChartRenderer;