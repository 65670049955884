import Card from "../card/card";
import { useEffect } from "react";
import { fullPageLoader, updateAlertMessage } from "../../../api-integration/commonSlice";
import { usePromptStatisticsMutation } from "../../../api-integration/secure/prompts";
import { useDispatch } from "react-redux";
import { convertToThousands } from "../../../util/util";
import TooltipComponent from "../bootstrap-component/tooltip-component";
import React from "react";

const Statistics = (props: any) => {
  const dispatch = useDispatch();

  const [getPromptsStatisticsAPI, { data: prompts, isLoading, isSuccess, isError, error }] =
  usePromptStatisticsMutation();
  
  useEffect(() => {
    dispatch(fullPageLoader(true));
    getPromptsStatisticsAPI({ 
      objId: Number(props?.objId), 
      statsType: props?.statsType
    });
  }, [props.reloadCheck, props?.objId, props?.statsType]);

  useEffect(() => {
  	if(props.reloadCheck){
      props.handleReloadCheck(false)
    }
    if (isSuccess || isError) {
      dispatch(fullPageLoader(false));
    }
    if (isError) {
      dispatch(updateAlertMessage({ status: 'error', message: prompts?.message }));
    }
    if (isSuccess) {
      if (prompts?.success == false) {
        dispatch(updateAlertMessage({ status: 'error', message: prompts?.message }));
      }
    }
  }, [isSuccess, isError]);
  
  return (
    <Card id={props?.id} titleType={1} 
      title="statistics.card.title" help={true} 
      Feedback={true} logo={true} like={true} 
      share={props.statsType=="corp"? false : true} 
      cardHeightClass={props?.cardHeightClass} 
      helpTitle="statistics.cardHelp.title"
      helpContent="statistics.cardHelp.content"
    >
      {prompts?.promptStats &&
        <div className="table-responsive">
          <table className="mb-4">
            <tbody>
              {Object.keys(prompts?.promptStats).map((key: any, index: number) => (
                <React.Fragment key={index}>
                  <tr>
                    <td colSpan={2}>
                      <h5 className={`bc-line-before mb-1 ${index === 1 ? 'mt-3' : ''}`}>{key}</h5>
                    </td>
                  </tr>
                    {prompts?.promptStats[key] && Object.keys(prompts?.promptStats[key]).map((element, innerIndex) => {
                      const isDataType = prompts?.promptStats[key][element]?.returnType === 'data';
                      return (       
                        <tr key={innerIndex}>
                          {
                            (isDataType) &&
                              <td className="text-end text-nowrap">
                                <TooltipComponent title={prompts?.promptStats[key][element]?.title ? prompts?.promptStats[key][element]?.title : ''}>
                                  <strong>{prompts?.promptStats[key][element]?.count ? convertToThousands(prompts?.promptStats[key][element]?.count) : '0'}</strong>
                                </TooltipComponent>
                              </td>
                          }
                        
                          <td className="text-nowrap ps-2" colSpan={isDataType ? 1 : 2}>
                            <TooltipComponent title={prompts?.promptStats[key][element]?.title ? prompts?.promptStats[key][element]?.title : ''}>
                              {prompts?.promptStats[key][element]?.label}
                            </TooltipComponent>
                          </td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                ))
              }
            </tbody>
          </table>
        </div>
      }
    </Card>
  )
}

export default Statistics;
